
import { Options, Vue } from "vue-class-component";
import BankingService from "../../service/BankingService.js";
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import AutoComplete from "primevue/autocomplete";
import SearchFilter from "../../components/SearchFilter.vue";
import ChartService from "../../service/ChartService.js";
import ProfilerService from "../../service/ProfilerService.js";
import PreviewBankingReceipt from "../../components/PreviewBankingReceipt.vue";
import { useStore, ActionTypes } from "../../store";

@Options({
  title: 'Banking',
  components: { AutoComplete, SearchFilter, PreviewBankingReceipt },
})
export default class Banking extends Vue {
  private lists = [];
  private profilerList = [];
  private accountHeadList = [];
  private bankList = [];
  private statement = "";
  private dialogTitle = "";
  private previewHeading = "";
  private receiptID = 0;
  private toast;
  private goToFirstLink = 0;
  private bankService;
  private bankingService;
  private chartService;
  private profilerService;
  private productDialog = false;
  private bankingActionDialog = false;
  private previewImageDialog = false;
  private submitted = false;
  private statusDialog = false;
  private checkPagination = true;
  private filterDialog = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Banking" , to: "banking" },
  ];
  private store = useStore();


  private item = {
    id: 0,
    status: "Active",
    profileID: 0,
    accountID: 0,
    branchID: 0,
  }; 
  
  private chequeActivity = [
    {name: "Outstanding"},
    {name: "Active"}
  ];

  private itemFilter = {
    keyword: "",
    filterType: "None",
    storeID: 0,
    date1: "",
    date2: "",
    type: "CHQ",
  };

  private state = reactive({
    bankID: 0,
    selectedProfile: "",
    receiptNo: "",
    accountHead: "",
    amount: 0,
    receiptDate: "",
    description: "",
    type: { key: "Cheque", value: "CHQ" },
    itemList: [
      {
        accountID: 0,
        accountHead: "",
        amount: 0,
        type: "",
      },
    ],
  });

  private bankingTypes = [
    { key: "Cheque", value: "CHQ" },
    { key: "Bank Deposit", value: "DPT" },
    { key: "Fund Transfer", value: "FTR" },
    { key: "Receive Fund", value: "REF" },
    { key: "Bank Expense", value: "EXP" },
  ];

  private validationRules = {
    type: {
      required,
    },
    bankID: {
      required,
    },
    receiptNo: {
      required,
    },
    selectedProfile: {
      required,
    },
    accountHead: {
      required,
    },
    amount: {
      required,
    },
    receiptDate: {
      required,
    },
    description: {
      required,
    },
  };

  private v$ = useVuelidate(this.validationRules, this.state);

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.bankingService = new BankingService();
    this.profilerService = new ProfilerService();
    this.chartService = new ChartService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.clearItems();
    this.submitted = false;
    this.dialogTitle = "Create Banking Activity";
    this.productDialog = true;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(isFormValid) {
    this.submitted = true;
    if (isFormValid) {
      this.setAccountingEntries();

      if (this.item.id != 0) {
        this.state.receiptDate = moment(this.state.receiptDate).format(
          "YYYY-MM-DD"
        );
        this.bankingService.updateItem(this.item, this.state).then((res) => {
          this.loadList(this.goToFirstLink);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.state.receiptDate = moment(this.state.receiptDate).format(
          "YYYY-MM-DD"
        );
        this.bankingService.saveItem(this.item, this.state).then((res) => {
          this.goToFirstLink = 0;
          this.loadList(this.goToFirstLink);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }
      this.productDialog = false;
      this.clearItems();
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Banking Activity";
    this.productDialog = true;
    this.bankingService.getItem(data).then((res) => {
      if (res.length > 0) {
        this.state.bankID = res[0].bank_id;
        this.state.selectedProfile = res[0].profile_name.profileName;
        this.state.receiptNo = res[0].transaction_no;
        this.state.accountHead = res[0].account_head;
        this.state.amount = Number(res[0].amount);
        this.state.receiptDate = res[0].receipt_date;
        this.state.description = res[0].description;
        this.state.type = this.getTheType(res[0].type);
        this.item.id = res[0].id;
        this.item.status = res[0].status;
        this.item.profileID = res[0].profile_name.id;
        this.item.accountID = res[0].account_id;
      }
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.bankingService.getItems(this.itemFilter, page).then((data) => {
      this.lists = data.records;
      this.statement = data.statement;
      this.totalRecords = data.totalRecords;
      this.limit = data.limit;
      this.bankList = data.banks;
    });
  }

  clearItems() {
    this.item.id = 0;
    this.item.status = "Active";
    this.item.profileID = 0;
    this.item.accountID = 0;

    this.state.bankID = 0;
    this.state.selectedProfile = "";
    this.state.accountHead = "";
    this.state.amount = 0;
    this.state.receiptDate = "";
    this.state.description = "";
    this.state.receiptNo = "";
    this.state.type = { key: "Cheque", value: "CHQ" };

    this.state.itemList = [
      {
        accountID: 0,
        accountHead: "",
        amount: 0,
        type: "",
      },
    ];
  }

  searchAccountHead(event) {
    setTimeout(() => {
      this.chartService.searchAccountHead(event.query.trim()).then((data) => {
        this.accountHeadList = data.records;
      });
    }, 500);
  }

  saveAccountHead(event) {
    const accountInfo = event.value;
    this.state.accountHead = accountInfo.account_name;
    this.item.accountID = accountInfo.id;
  }

  searchProfiler(event) {
    setTimeout(() => {
      this.profilerService.searchProfiler(event.query.trim()).then((data) => {
        this.profilerList = data.records;
      });
    }, 500);
  }

  saveProfile(event) {
    const profileInfo = event.value;
    this.state.selectedProfile = profileInfo.account_title;
    this.item.profileID = profileInfo.id;
  }

  fixDigits(amt) {
    return Number(amt).toFixed(2);
  }

  formatDate(date) {
    return moment(date).format("DD-MM-YYYY");
  }

  formatDateTime(date) {
    return moment(date).format("DD-MM-YYYY hh:mm A");
  }

  openFilterDialog() {
    this.dialogTitle = "Filter Cheque/Fund Transfer/Receive Fund";
    this.filterDialog = true;
  }

  updateFilterStatus(obj) {
    if (obj != null && obj.loading == "Yes") {
      this.itemFilter.filterType = obj.filterName.value;
      this.itemFilter.storeID = obj.storeID.id;
      this.itemFilter.date1 = obj.date1;
      this.itemFilter.date2 = obj.date2;
      this.itemFilter.keyword = "";
      this.goToFirstLink = 0;
      this.loadList(this.goToFirstLink);
    }
    this.filterDialog = false;
  }

  loadSearchData() {
    this.submitted = true;
    if (this.itemFilter.keyword) {
      this.goToFirstLink = 0;
      this.loadList(this.goToFirstLink);
    }
  }

  setAccountingEntries() {
    this.state.itemList = [];

    if (this.state.type.value == "CHQ" || this.state.type.value == "FTR" || this.state.type.value == "EXP") {
      this.state.itemList.push({
        accountID: this.item.accountID,
        accountHead: this.state.accountHead,
        amount: this.state.amount,
        type: "Debit",
      });

      this.state.itemList.push({
        accountID: 8,
        accountHead: "Cash in Bank",
        amount: this.state.amount,
        type: "Credit",
      });
    } else if (
      this.state.type.value == "REF" ||
      this.state.type.value == "DPT"
    ) {
      this.state.itemList.push({
        accountID: 8,
        accountHead: "Cash in Bank",
        amount: this.state.amount,
        type: "Debit",
      });

      this.state.itemList.push({
        accountID: this.item.accountID,
        accountHead: this.state.accountHead,
        amount: this.state.amount,
        type: "Credit",
      });
    } else {
      this.state.itemList = [
        {
          accountID: 0,
          accountHead: "",
          amount: 0,
          type: "",
        },
      ];
    }
  }

  setDefaultProfiler() {
    if (this.state.type.key == "Bank Deposit" || this.state.type.key == "Bank Expense") {
      this.state.selectedProfile = "None";
      this.item.profileID = 0;
    }
  }

  getTheType(type) {
    let obj = { key: "", value: "" };
    this.bankingTypes.forEach((v) => {
      if (v.value == type) {
        obj.key = v.key;
        obj.value = v.value;
      }
    });

    return obj;
  }

  openPreviewDialog(data) {
    if (data.type == "CHQ") {
      this.dialogTitle = "Preview Bank Cheque";
      this.previewHeading = "Bank Cheque";
    } else if (data.type == "DPT") {
      this.dialogTitle = "Preview Bank Deposit";
      this.previewHeading = "Bank Deposit";
    } else if (data.type == "FTR") {
      this.dialogTitle = "Preview Fund Transfer";
      this.previewHeading = "Fund Transfer";
    } else if (data.type == "REF") {
      this.dialogTitle = "Preview Receive Fund";
      this.previewHeading = "Receive Fund";
    }
    else if (data.type == "EXP")
    {
      this.dialogTitle = "Preview Bank Expense";
      this.previewHeading = "Bank Expense";
    } else {
      this.dialogTitle = "None";
      this.previewHeading = "None";
    }

    this.previewImageDialog = true;
    this.receiptID = data.id;
  }

  updatePreviewStatus() {
    this.previewImageDialog = false;
  }

  openActivityDialog(d)
  {
    this.item.id = d.id;
    this.item.status = d.status;
    this.bankingActionDialog = true;
  }

  updateBankActivity()
  {
    this.bankingActionDialog = false;
    this.bankingService.updateBankStatus(this.item).then((res) => {
      this.loadList(this.goToFirstLink);
      this.toast.handleResponse(res);
    });
  }

  get currency() {
    return this.store.getters.getCurrency;
  }
}
