
import { Options, Vue } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import FileUpload from 'primevue/fileupload';

@Options({
  components: {
    FileUpload,
  },
  props: {
    uploaderDetail: Object,
  },
  watch: {
    uploaderDetail(obj) {
      if (obj.status == true) {
        this.dialogTitle = obj.dialogTitle;  
        this.imageType = obj.imageType;  
        this.openDialog();
      }
    },
  },
  emits: ["updateUploaderStatus"],
})
export default class FileUploader extends Vue {
  private toast;
  private submitted = false;
  private productDialog = false;
  private dialogTitle = "";
  private imageType = "";

  created() {
    this.toast = new Toaster();
  }

  mounted() {
    
  }

  openDialog() {
    this.submitted = false;
    this.productDialog = true;
  }

  closeDialogBox() {
    this.submitted = false;
    this.productDialog = false;
    this.$emit("updateUploaderStatus",[]);
  }


  myUploader(e)
  {
    this.$emit("updateUploaderStatus",e.files);
    this.submitted = false;
    this.productDialog = false;
  }
}
