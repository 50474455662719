
import { Options, Vue } from "vue-class-component";
import UserService from "../../service/UserService.js";
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { email, required, minLength } from "@vuelidate/validators";
import Toaster from "../../helpers/Toaster";
import FileUploader from "../../components/FileUploader.vue";

@Options({
   title: 'Users',
   components: {
    FileUploader,
  },
})
export default class Users extends Vue {
  private lists = [];
  private goToFirstLink = 0;
  private UserService;
  private keyword = "";
  private productDialog = false;
  private uploaderStatus = false;
  private pinDialog = false;
  private editProductDialog = false;
  private submitted = false;
  private statusDialog = false;
  private checkPagination = true;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };

  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Users", to: "users" },
  ];

  private toast;

  private item = {
    id: 0,
    branchId: "",
    role: "",
    address: "",
    contact: "",
    image: "default.jpg",
    description: "",
    status: "Active",
  };

  private state = reactive({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  private stateUpdateUser = reactive({
    name: "",
    email: "",
  });

  private validationRules = {
    name: {
      required,
    },
    email: {
      required,
      email,
    },
    password: {
      minLength: minLength(6),
      required,
    },
    confirmPassword: {
      minLength: minLength(6),
      required,
    },
  };

  private editValidationRules = {
    name: {
      required,
    },
    email: {
      required,
      email,
    },
  };

  private v$ = useVuelidate(this.validationRules, this.state);
  private v2$ = useVuelidate(this.editValidationRules, this.stateUpdateUser);

  private userRoles;
  private storesList;
  private dialogTitle;
  private dialogCallback;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.UserService = new UserService();
    this.toast = new Toaster();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
  
    this.clearItem();

    this.editProductDialog = false;
    this.submitted = false;
    this.dialogTitle = "Add New User";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.editProductDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(isFormValid) {
    this.submitted = true;
    if (isFormValid) {
      if (this.item.id != 0) {
        this.UserService.updateItem(
          this.item,
          this.stateUpdateUser.name,
          this.stateUpdateUser.email
        ).then((res) => {
          this.loadList(this.goToFirstLink);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });

        this.productDialog = false;
        this.editProductDialog = false;
        this.clearItem();
      } else {
        if (this.state.password == this.state.confirmPassword) {
          this.UserService.saveItem(
            this.item,
            this.state.name,
            this.state.email,
            this.state.password
          ).then((res) => {
            this.goToFirstLink = 0;
            this.loadList(this.goToFirstLink);
            //SHOW NOTIFICATION
            this.toast.handleResponse(res);
          });

          this.productDialog = false;
          this.editProductDialog = false;
          this.clearItem();
        } else {
          this.toast.showError("Password & Confirm Password is not matching");
        }
      }
    }
  }

  changeStorePin(isFormValid) {
    this.submitted = true;
    if (isFormValid) {
      if (this.state.password == this.state.confirmPassword) {
        this.UserService.changePin(this.item, this.state.password).then(
          (res) => {
            this.loadList(this.goToFirstLink);
            //SHOW NOTIFICATION
            this.toast.handleResponse(res);
          }
        );

        this.pinDialog = false;
        this.clearItem();
      } else {
        this.toast.showError("Password & Confirm Password is not matching");
      }
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {

    this.submitted = false;
    this.dialogTitle = "Update User";
    this.productDialog = false;
    this.editProductDialog = true;

    this.UserService.getItem(data).then((res) => {
      if (res.length > 0) {
        //FIND THE CATEGORY TYPE AND MAKE IT AS SELECTED IN EDIT DIALOG BOX.
        this.item.branchId = res[0].branch_id;
        this.item.role = res[0].role;
        this.item.address = res[0].address == null ? "" : res[0].address;
        this.item.contact = res[0].contact == null ? "" : res[0].contact;
        this.item.image = res[0].image;
        this.item.description =
          res[0].description == null ? "" : res[0].description;
        this.item.status = res[0].status;
        this.item.id = res[0].id;
        this.stateUpdateUser.name = res[0].name;
        this.stateUpdateUser.email = res[0].email;

        this.item.role = res[0].role;

        this.storesList.filter((elem) => {
          if (elem.id == res[0].branch_id) {
            this.item.branchId = elem;
          }
        });
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data) {
    this.item.id = data.id;
    this.state.name = data.name;
    this.statusDialog = true;
  }

  changeUserPin(data) {
    this.item.id = data.id;
    this.state.name = data.name;
    this.state.email = data.email;
    this.pinDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.item.status = "Delete";
    this.UserService.changeStatus(this.item).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.UserService.getItems(this.keyword, page).then((data) => {
      this.storesList = data.branches;
      this.lists = data.records;
      this.totalRecords = data.totalRecords;
      this.userRoles = data.userRoles;
      this.limit = data.limit;
    });
  }

  clearItem() {
    this.item = {
      id: 0,
      branchId: "",
      role: "",
      address: "",
      contact: "",
      image: "default.jpg",
      description: "",
      status: "Active",
    };

    this.stateUpdateUser.name = "";
    this.stateUpdateUser.email = "";

    this.state.name = "";
    this.state.email = "";
    this.state.password = "";
    this.state.confirmPassword = "";
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.goToFirstLink = 0;
      this.loadList(0);
    }
  }
  
  openFileUploader(data)
  {
    this.item.id = data.id;
    this.uploaderStatus = true;
  }

  updateUploaderStatus(params)
  {
    this.uploaderStatus = false;
    if(params.length > 0)
    {
      this.UserService.uploadProfileImage(params[0],this.item).then((res) => {
        this.toast.handleResponse(res);
      });
    }
  }

  getImgUrl(pic) {
    let userImg = ''
    try
    {
      userImg = require('@/assets/users/'+pic).default;
    }
    catch (error)
    {
      userImg = require('@/assets/users/default.jpg').default;
    }
    return userImg;
  }
}
