
import { Options, Vue } from "vue-class-component";
import ChartService from "../../service/ChartService.js";
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength,maxLength} from "@vuelidate/validators";
import Toaster from "../../helpers/Toaster";

@Options({
  title: 'Chart of Accounts',
  components: {},
})
export default class ChartOfAccounts extends Vue {
  private lists = [];
  private dialogTitle;
  private keyword = '';
  private toast;
  private goToFirstLink = 0;
  private ChartService;
  private productDialog = false;
  private submitted = false;
  private statusDialog = false;
  private checkPagination = true;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Chart of Accounts" , to: "chart-of-accounts"  },
  ];

  private item = {
    id: 0,
    expenseType: { key: "None" },
    status: "Active",
  };
  

  private state = reactive({
    accountName: "",
    code: "",
    nature: { key: "Assets" },
    type: { key: "Current" }
  });

  private validationRules = {
    accountName: {
      required,
    },
    nature: {
      required,
    },
    code: {
      required,
    },
    type: {
      required
    },
  };
  

  private v$ = useVuelidate(this.validationRules, this.state);

  private accountNature = [
    { key: "Assets" },
    { key: "Liability" },
    { key: "Equity" },
    { key: "Revenue" },
    { key: "Expense" }
  ];

  private accountType = [
    { key: "Current" },
    { key: "Non-Current" }
  ];

  private expenseType = [
    { key: "None" },
    { key: "Store Expense" },
    { key: "Bank Expense" }
  ];

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  created() {
    this.ChartService = new ChartService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.clearItem();

    this.submitted = false;
    this.dialogTitle = "Add New Account";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(isFormValid) {
    this.submitted = true;
    if (isFormValid) {
      if (this.item.id != 0) {
        this.ChartService
          .updateItem(
            this.item,
            this.state
          )
          .then((res) => {
            this.loadList(this.goToFirstLink);
            //SHOW NOTIFICATION
            this.toast.handleResponse(res);
          });
      } else {
        this.ChartService
          .saveItem(
            this.item,
            this.state
          )
          .then((res) => {
            this.goToFirstLink = 0;
            this.loadList(this.goToFirstLink);
            //SHOW NOTIFICATION
            this.toast.handleResponse(res);
          });
      }

      this.productDialog = false;
      this.clearItem();
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Account";
    this.productDialog = true;

    this.ChartService.getItem(data).then((res) => {
      if (res.length > 0) {
        this.state.accountName      = res[0].account_name;
        this.state.code             = res[0].account_code;
        this.state.nature.key       = res[0].account_nature;
        this.state.type.key         = res[0].account_type;
        this.item.expenseType.key   = res[0].expense_type;
        this.item.status            = res[0].status;
        this.item.id                = res[0].id;
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data) {
    this.item.id = data.id;
    this.state.accountName = data.account_name;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.item.status = "Delete";
    this.ChartService.changeStatus(this.item).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.ChartService.getItems(this.keyword,page).then((data) => {
      this.lists = data.records;
      this.totalRecords = data.totalRecords;
      this.limit = data.limit;
    });
  }

  clearItem() {
    this.item = {
      id: 0,
      expenseType: { key: "None" },
      status: "Active",
    };
  
    this.state.accountName =  "";
    this.state.code =  "";
    this.state.nature = { key: "Assets" };
    this.state.type = { key: "Current" };
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.goToFirstLink = 0;
      this.loadList(0);
    }
  }

  setExpenseType()
  {
    if(this.state.nature.key == 'Expense')
    {
      this.item.expenseType.key =  "Store Expense";
    }
  }
}
