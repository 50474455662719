import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "top-menu-vue element-to-hide" }
const _hoisted_2 = { style: {"height":"0.2em","margin":"0","background-color":"#eee"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menubar = _resolveComponent("Menubar")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_Menubar, { model: _ctx.menuItems }, null, 8, ["model"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.progressBar)
        ? (_openBlock(), _createBlock(_component_ProgressBar, {
            key: 0,
            mode: "indeterminate",
            style: {"height":"0.2em"}
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}