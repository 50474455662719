
import { Vue } from "vue-class-component";
import footerService from "../service/footerService";

export default class Footer extends Vue {
  private userName = "";
  private branchName = "";
  private copyRight = "";
  private footerService;

  created() {
    this.footerService = new footerService();
  }

  mounted() {
    this.loadList();
  }

  loadList() {
    this.footerService.branchDetail().then((data) => {
      this.userName = data.userName;
      this.branchName = data.branchName;
      this.copyRight = data.copyright;
    });
  }
}
