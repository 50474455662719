
import { Options, Vue } from "vue-class-component";
import PaymentMethod from "../../service/PaymentMethod.js";
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import {required, minValue} from "@vuelidate/validators";
import Toaster from "../../helpers/Toaster";
import SelectButton from 'primevue/selectbutton';


interface bankList{
  id: number;
  bank: string;
}

@Options({
  title: 'Payment Methods',
  components: {SelectButton},
})
export default class PaymentMethods extends Vue {
  private lists = [];
  private bankList: bankList [] = [];
  private dialogTitle;
  private toast;
  private goToFirstLink = 0;
  private paymentMethod;
  private productDialog = false;
  private submitted = false;
  private statusDialog = false;
  private checkPagination = true;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Payment Methods", to: "payment-method" },
  ];

  private item = {
    id: 0,
    description: "",
    status: "",
    chargeCustomer: "Store",
  };

  private chargeCustomerList = [
    {
      name : 'Store',
    },
    {
      name : 'Customer',
    }
  ];

  private state = reactive({
    cardName: "",
    cardCharges: 0,
    bankID: 0,
  });

  private validationRules = {
    cardName: {
      required,
    },
    cardCharges: {
      required,
    },
    bankID: {
      required,
      minValue: minValue(1)
    },
  };

  private v$ = useVuelidate(this.validationRules, this.state);

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.paymentMethod = new PaymentMethod();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.state.cardName = "";
    this.state.cardCharges = 0;
    this.state.bankID = 0;

    this.item = {
      id: 0,
      status: "Active",
      chargeCustomer: "Store",
      description: "",
    };

    this.submitted = false;
    this.dialogTitle = "Add New Card";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(isFormValid) {
    this.submitted = true;
    if (isFormValid) {
      if (this.item.id != 0) {
        this.paymentMethod
          .updateItem(this.item, this.state)
          .then((res) => {
            this.loadList(this.goToFirstLink);
            //SHOW NOTIFICATION
            this.toast.handleResponse(res);
          });
      } else {
        this.paymentMethod
          .saveItem(this.item, this.state)
          .then((res) => {
            this.goToFirstLink = 0;
            this.loadList(this.goToFirstLink);
            //SHOW NOTIFICATION
            this.toast.handleResponse(res);
          });
      }

      this.productDialog = false;
      this.item.id = 0;
      this.item.description = "";
      this.item.chargeCustomer = "Store";
      this.item.status = "Active";

      this.state.cardName = "";
      this.state.cardCharges = 0;
      this.state.bankID = 0;
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Card";
    this.productDialog = true;

    this.paymentMethod.getItem(data).then((res) => {
      if (res.length > 0) {
        //FIND THE CATEGORY TYPE AND MAKE IT AS SELECTED IN EDIT DIALOG BOX.
        this.state.cardName       = res[0].card_name;
        this.state.cardCharges    = res[0].card_charges;
        this.state.bankID         = res[0].bank_id;
        this.item.description     = res[0].description == null ? "" : res[0].description;
        this.item.status          = res[0].status;
        this.item.id              = res[0].id;
        this.item.chargeCustomer  = res[0].charge_customer;
      }
    });
  }


  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.item.status = "Delete";
    this.paymentMethod.changeStatus(this.item).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.paymentMethod.getItems(this.item, page).then((data) => {
      this.lists = data.records;
      this.totalRecords = data.totalRecords;
      this.limit = data.limit;
      this.bankList = data.banks;
    });
  }

  confirmChangeStatus(data) {
    this.item.id = data.id;
    this.state.cardName = data.card_name;
    this.statusDialog = true;
  }
}
