
import { Options, mixins } from "vue-class-component";
import { ref } from "vue";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";

interface IBranch {
    name: string;
}

interface IUser {
    name: string;
}

interface IReport {
    activityAction: string;
    userRole: string;
    createdAt: string;
    userDetails: IUser;
    branchDetails: IBranch;
}

@Options({
    title: 'Activity Report',
    components: {},
})
export default class ActivityReport extends mixins(UtilityOptions) {
    private dt = ref();
    private lists: IReport[] = [];
    private reportService;
    private resultTitle = "";
    private productDialog = false;
    private loading = false;
    private home = { icon: "pi pi-home", to: "/" };
    private items = [
        { label: "Reports", to: "reports" },
        { label: "Activity Report", to: "activity-report" },
    ];

    private searchFilters = {
        id: "",
        date1: "",
        date2: "",
        filterType: "None",
        storeID: 0,
    };

    private filterDates = [];
    private dialogTitle;
    private submitted = false;
    private filterBranch = [];

    //CALLING WHENEVER COMPONENT LOADS
    created() {
        this.reportService = new StoreReports();
    }

    //CALLNING AFTER CONSTRUCTOR GET CALLED
    mounted() {
        this.storeList();
        this.loadList();
    }

    //OPEN DIALOG TO ADD NEW ITEM
    openDialog() {
        this.submitted = false;
        this.dialogTitle = "Filter Report";
        this.productDialog = true;
    }

    storeList() {
        this.reportService.getFilterList().then((res) => {
            this.filterBranch = res.stores;
            this.filterDates = res.datesList;
        });
    }

    // USED TO GET SEARCHED ASSOCIATE
    loadList() {
        this.loading = true;
        this.reportService
            .userActivityReport(this.searchFilters)
            .then((res) => {
                const data = this.camelizeKeys(res);
                this.resultTitle = data.resultTitle;
                this.lists = data.record;
                this.loading = false;
            });
        this.productDialog = false;
    }

    get rList() {
        const l: IReport[] = [];

        this.lists.forEach((e) => {
            e.createdAt = this.formatDateTime(e.createdAt);
            l.push(e);
        });

        return l;
    }
}
