
import { Options, mixins } from "vue-class-component";
import { ActionTypes } from "../../store";
import Toaster from "../../helpers/Toaster";
import router from "../../router";
import UtilityOptions from "../../mixins/UtilityOptions";

@Options({
  components: {},
  title: "Forbidden Access",
})
export default class ForbiddenAccess extends  mixins(UtilityOptions) {

  logOut() {
    const toast = new Toaster();

    const res = {
      alert: "info",
      msg: "Sign out successfully",
    };

    toast.handleResponse(res);

    this.store.dispatch(ActionTypes.AUTH_LOGOUT, "");

    router.push({ path: "/login" });
  }
 
}
