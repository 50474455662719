
import { Options, Vue } from "vue-class-component";
import ProfilerService from "../../service/ProfilerService.js";
import Toaster from "../../helpers/Toaster";
import ProfilerDialog from "../../components/ProfilerDialog.vue";

@Options({
  title: 'Profilers',
  components: {
    ProfilerDialog,
  },
})
export default class Profilers extends Vue {
  private lists = [];
  private keyword = "";
  private dialogTitle = "";
  private statusType = "New";
  private toast;
  private profilerID = 0;
  private currentUserID = 0;
  private goToFirstLink = 0;
  private profilerService;
  private checkPagination = true;
  private profileStatus = false;
  private statusDialog = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Profilers", to: "profilers" },
  ];

  private item = {
    id: 0,
    accountTitle: "",
    status: "Active",
  };

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  created() {
    this.profilerService = new ProfilerService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data) {
    this.item.id = data.id;
    this.item.accountTitle = data.account_title;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.item.status = "Delete";
    this.profilerService.changeStatus(this.item).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.profilerService.getItems(this.keyword, page).then((data) => {
      this.lists = data.records;
      this.totalRecords = data.totalRecords;
      this.limit = data.limit;
      this.currentUserID = data.currentUserID;
    });
  }

  loadSearchData() {
    if (this.keyword) {
      this.goToFirstLink = 0;
      this.loadList(0);
    }
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.dialogTitle = "Add New Profile";
    this.profileStatus = true;
    this.statusType = "New";
  }

  editIem(data) {
    this.statusType = "Update";
    this.profilerID = data.id;
    this.dialogTitle = "Update Profile";
    this.profileStatus = true;
    this.statusType = "Update";
  }

  updateProfilerStatus(res) {
    this.profileStatus = false;

    if (res[0] == "load") {
      this.loadList(this.goToFirstLink);
    }
  }
}
