
import { Options, mixins } from "vue-class-component";
import UtilityOptions from "../../mixins/UtilityOptions";

@Options({
  title: 'Report',
  components: {},
})

export default class Report extends mixins(UtilityOptions) {
  private home = { icon: "pi pi-home", to: "/" };
  private items = [{ label: "Report" }];
  private reportList = [
    {
      label: "User Report",
      image: require("@/assets/menu-icons/reports/user_report.png").default,
      to: "/store/user-report",
      description:"",
    },
    {
      label: "Sales/Refund Report",
      image: require("@/assets/menu-icons/reports/sales_refund_report.png").default,
      to: "/store/sales-refund-reports",
      description:"",
    },
    {
      label: "Transfer Stock Report",
      image: require("@/assets/menu-icons/reports/stock_transfer.png").default,
      to: "/store/transfer-reports",
      description:"",
    },
    {
      label: "Purchasing Report",
      image: require("@/assets/menu-icons/reports/purchasing.png").default,
      to: "/store/purchasing-report",
      description:"",
    },
    {
      label: "Performance Report",
      image: require("@/assets/menu-icons/reports/performance.png").default,
      to: "/store/performance-report",
      description:"",
    },
    {
      label: "General Journal",
      image: require("@/assets/menu-icons/reports/general.png").default,
      to: "/store/general-journal-report",
      description:"",
    },
    {
      label: "Income Statement",
      image: require("@/assets/menu-icons/reports/income.png").default,
      to: "/store/income-statement-report",
      description:"",
    },
    {
      label: "Trial Balance",
      image: require("@/assets/menu-icons/reports/trail_balance.png").default,
      to: "/store/trial-balance-report",
      description:"",
    },
    {
      label: "Ledger Statement",
      image: require("@/assets/menu-icons/reports/ledger.png").default,
      to: "/store/ledger-statement",
      description:"",
    },
    {
      label: "Tax Report",
      image: require("@/assets/menu-icons/reports/tax.png").default,
      to: "/store/tax-report",
      description:"",
    },
    {
      label: "Bank Reconciliation",
      image: require("@/assets/menu-icons/reports/bank.png").default,
      to: "/store/bank-reconciliation",
      description:"",
    },
    {
      label: "Account Statement",
      image: require("@/assets/menu-icons/reports/account_statement.png").default,
      to: "/store/account-statement",
      description:"",
    },
     {
      label: "Stock Report",
      image: require("@/assets/menu-icons/reports/stock.png").default,
      to: "/store/stock-report",
      description:"",
    },
    {
      label: "Stock Alert Report",
      image: require("@/assets/menu-icons/reports/alert.png").default,
      to: "/store/stock-alert-report",
      description:"",
     },
    // {
    //   label: "Activity Report",
    //   image: require("@/assets/menu-icons/reports/activity.png").default,
    //   to: "/store/activity-report",
    //   description:"",
    // },
    {
      label: "Stock Expiry Report",
      image: require("@/assets/menu-icons/reports/expiry.png").default,
      to: "/store/expiry-report",
      description:"",
    }
  ];
}
