import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a1329474"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "p-grid p-p-0 p-mx-2" }
const _hoisted_3 = { class: "p-col-6 text-right" }
const _hoisted_4 = { class: "p-col-6 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.copyRight), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.userName) + " | " + _toDisplayString(_ctx.branchName), 1)
    ])
  ]))
}