
import { Options, Vue } from "vue-class-component";
import PrinterReceiptService from "../../service/PrinterReceiptService.js";
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required} from "@vuelidate/validators";
import Toaster from "../../helpers/Toaster";

@Options({
  title: 'Printer Receipt Content',
  components: {},
})
export default class ReceiptPrinters extends Vue {
  private lists = [];
  private dialogTitle;
  private keyword = '';
  private toast;
  private goToFirstLink = 0;
  private currentStoreID = 0;
  private printerReceipt;
  private productDialog = false;
  private submitted = false;
  private statusDialog = false;
  private checkPagination = true;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private storeList = [];
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Printer receipt content", to: "receipt-printer" },
  ];

  private selectedStore = {
    id: 0
  };

  private item = {
    id: 0,
    receiptHeading: "",
    receiptContent: "",
    status: "Active",
  };
  

  private state = reactive({
    receiptPriority: 0,
  });

  private validationRules = {
    receiptPriority: {
      required,
    }
  };
  

  private v$ = useVuelidate(this.validationRules, this.state);

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  created() {
    this.printerReceipt = new PrinterReceiptService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.clearItem();

    this.submitted = false;
    this.dialogTitle = "Add New Content";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(isFormValid) {
    this.submitted = true;
    if (isFormValid) {
      if (this.item.id != 0) {
        this.printerReceipt
          .updateItem(
            this.item,
            this.state
          )
          .then((res) => {
            this.loadList(this.goToFirstLink);
            //SHOW NOTIFICATION
            this.toast.handleResponse(res);
          });
      } else {
        this.printerReceipt
          .saveItem(
            this.item,
            this.state,
            this.currentStoreID
          )
          .then((res) => {
            this.goToFirstLink = 0;
            this.loadList(this.goToFirstLink);
            //SHOW NOTIFICATION
            this.toast.handleResponse(res);
          });
      }

      this.productDialog = false;
      this.clearItem();
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Content";
    this.productDialog = true;

    this.printerReceipt.getItem(data).then((res) => {
      if (res.length > 0) {
        this.state.receiptPriority     = res[0].receipt_priority;
        this.item.status               = res[0].status;
        this.item.receiptHeading       = res[0].receipt_heading == null ? "" : res[0].receipt_heading;
        this.item.receiptContent       = res[0].receipt_content == null ? "" : res[0].receipt_content;
        this.item.id                   = res[0].id;
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data) {
    this.item.id = data.id;
    this.item.receiptHeading = data.receipt_heading;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.item.status = "Delete";
    this.printerReceipt.changeStatus(this.item).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.printerReceipt.getItems(this.selectedStore.id,this.keyword,page).then((data) => {
      this.lists              = data.records;
      this.totalRecords       = data.totalRecords;
      this.storeList          = data.stores;
      this.limit              = data.limit;
      this.currentStoreID     = data.currentStoreID;
    });
  }

  clearItem() {

    this.item = {
      id: 0,
      receiptHeading: "",
      receiptContent: "",
      status: "Active",
    };

    this.state.receiptPriority =  0;
  }
}
