
import { ref } from "vue";
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";
import ProfilerService from "../../service/ProfilerService.js";
import UserService from "../../service/UserService.js";
import AutoComplete from "primevue/autocomplete";

@Options({
  title: 'Sales/Refund Report',
  components: {AutoComplete},
})

export default class SalesRefundReport extends mixins(UtilityOptions) {
  private dt = ref();
  private lists  = [];
  private profilerList = [];
  private userList = [];
  private reportService;
  private profilerService;
  private userService;
  private resultTitle = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Reports", to: "reports" },
    { label: "Sales/Refund Report", to: "sales-refund-reports"  },
  ];

  private searchFilters = {
    id: "",
    date1: "",
    date2: "",
    filterType: "None",
    storeID: 0,
    type: 'Sales',
    customerID: 0,
    userID: 0,
    brandName: 'All',
    sectorName: 'All',
    categoryName: 'All',
    productType: 'All',
    batchNo: '',
    customerName: 'All',
    UserName: 'All',
  };

  private taxNames = [
    {
      taxName: "",
      show: false,
      optionalReq: "",
      taxValue: 0,
      accountHead: "",
      accountID: 0,
    },
    {
      taxName: "",
      show: false,
      optionalReq: "",
      taxValue: 0,
      accountHead: "",
      accountID: 0,
    },
    {
      taxName: "",
      show: false,
      optionalReq: "",
      taxValue: 0,
      accountHead: "",
      accountID: 0,
    },
  ];

  private reportTypes = [
    {
      name: 'Sales',
    },
    {
      name: 'Refund',
    },
  ];

  private brands:any = [];
  private sectors:any = [];
  private categories:any = [];
  private productTypes:any = [];

  private filterDates = [];
  private dialogTitle;
  private submitted = false;
  private filterBranch = [];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
    this.profilerService = new ProfilerService();
    this.userService = new UserService();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.submitted = false;
    this.dialogTitle = "Filter Report";
    this.productDialog = true;
  }

  storeList()
  {
    this.reportService.getFilterList().then((res) => {
      this.filterBranch  = res.stores;
      this.filterDates   = res.datesList;
      this.brands        = res.brands;
      this.sectors       = res.brandSector;
      this.categories    = res.categories;
      this.productTypes  = res.productTypes;

      const defaultFilter = {id:0,option_name:'All'};
      this.brands.push(defaultFilter);
      this.sectors.push(defaultFilter);
      this.categories.push(defaultFilter);
      this.productTypes.push(defaultFilter);

      this.taxNames = [];

        this.taxNames.push({
          taxName: res.storeTaxes[0].tax_name_1,
          show: res.storeTaxes[0].show_1,
          optionalReq: res.storeTaxes[0].required_optional_1,
          taxValue:
            res.storeTaxes[0].show_1 == "true"
              ? Number(res.storeTaxes[0].tax_value_1)
              : 0,
          accountHead: res.storeTaxes[0].tax_name1.chartName,
          accountID: res.storeTaxes[0].link1,
        });

        this.taxNames.push({
          taxName: res.storeTaxes[0].tax_name_2,
          show: res.storeTaxes[0].show_2,
          optionalReq: res.storeTaxes[0].required_optional_2,
          taxValue:
            res.storeTaxes[0].show_2 == "true"
              ? Number(res.storeTaxes[0].tax_value_2)
              : 0,
          accountHead: res.storeTaxes[0].tax_name2.chartName,
          accountID: res.storeTaxes[0].link2,
        });

        this.taxNames.push({
          taxName: res.storeTaxes[0].tax_name_3,
          show: res.storeTaxes[0].show_3,
          optionalReq: res.storeTaxes[0].required_optional_3,
          taxValue:
            res.storeTaxes[0].show_3 == "true"
              ? Number(res.storeTaxes[0].tax_value_3)
              : 0,
          accountHead: res.storeTaxes[0].tax_name3.chartName,
          accountID: res.storeTaxes[0].link3,
        });
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading = true;
    this.reportService.salesRefundReport(this.searchFilters).then((res) => {
        const data = this.camelizeKeys(res);
        this.resultTitle = data.resultTitle;
        this.lists = data.record;
        this.loading = false;
      });
    this.productDialog = false;
  }

  searchProfiler(event) {
    setTimeout(() => {
      this.profilerService.searchProfiler(event.query.trim()).then((data) => {
        this.profilerList = data.records;
      });
    }, 200);
  }
  
  searchUser(event) {
    setTimeout(() => {
      this.userService.searchUser(event.query.trim()).then((data) => {
        this.userList = data.records;
      });
    }, 200);
  }

  saveProfile(event) {
    const profileInfo = event.value;
    this.searchFilters.customerName = profileInfo.account_title;
    this.searchFilters.customerID = profileInfo.id;
  }
  
  saveUser(event) {
    const userInfo = event.value;
    this.searchFilters.UserName = userInfo.name;
    this.searchFilters.userID = userInfo.id;
  }

  get currency() {
    return this.store.getters.getCurrency;
  }
}
