
import { Options, Vue } from "vue-class-component";
import BalanceService from "../../service/BalanceService.js";
import ProfilerService from "../../service/ProfilerService.js";
import ChartService from "../../service/ChartService.js";
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import AutoComplete from "primevue/autocomplete";
import SearchFilter from "../../components/SearchFilter.vue";
import PreviewReceipt from "../../components/PreviewReceipt.vue";
import { useStore, ActionTypes } from "../../store";

@Options({
  title: 'User Balance',
  components: {
    AutoComplete,
    SearchFilter,
    PreviewReceipt,
  },
})
export default class UserBalance extends Vue {
  private lists = [];
  private profilerList = [];
  private store = useStore();
  private statement = "";
  private dialogTitle = "";
  private toast;
  private goToFirstLink = 0;
  private previewHeading = "";
  private receiptID = 0;
  private balanceService;
  private profilerService;
  private chartService;
  private previewImageDialog = false;
  private productDialog = false;
  private filterDialog = false;
  private submitted = false;
  private statusDialog = false;
  private checkPagination = true;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "User Balances", to: "user-balance" },
  ];

  private itemFilter = {
    keyword: "",
    filterType: "None",
    storeID: 0,
    date1: "",
    date2: "",
    type: "OPB",
  };

  private item = {
    id: 0,
    transactionID: 0,
    status: "Active",
    profileID: 0,
    type: "OPB",
    itemList: [
      {
        accountID: 0,
        accountHead: "",
        debitAmount: 0,
        creditAmount: 0,
      },
    ],
  };

  private accountList = [{ key: "Debit" }, { key: "Credit" }];

  private state = reactive({
    description: "",
    balanceDate: "",
    selectedProfile: "",
    amount: 0,
    account: "Debit",
  });

  private validationRules = {
    description: {
      required,
    },
    balanceDate: {
      required,
    },
    selectedProfile: {
      required,
    },
    amount: {
      required,
    },
    account: {
      required,
    },
  };

  private v$ = useVuelidate(this.validationRules, this.state);

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  created() {
    this.balanceService = new BalanceService();
    this.profilerService = new ProfilerService();
    this.chartService = new ChartService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.clearItem();
    this.submitted = false;
    this.dialogTitle = "Create User Balance";
    this.productDialog = true;
  }

  openFilterDialog() {
    this.dialogTitle = "Filter User Balance";
    this.filterDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(isFormValid) {
    this.submitted = true;
    if (isFormValid) {
      this.setAccountingEntries();

      if (this.item.id != 0) {
        this.state.balanceDate = moment(this.state.balanceDate).format(
          "YYYY-MM-DD"
        );

        this.balanceService.update(this.item, this.state).then((res) => {
          this.loadList(this.goToFirstLink);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.state.balanceDate = moment(this.state.balanceDate).format(
          "YYYY-MM-DD"
        );

        this.balanceService.save(this.item, this.state).then((res) => {
          this.goToFirstLink = 0;
          this.loadList(this.goToFirstLink);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.clearItem();
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editItem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Balance";
    this.productDialog = true;

    this.balanceService.getItem(data).then((res) => {
      if (res != null) {
        this.item.id = res.balance.id;
        this.state.selectedProfile = res.balance.profile_name;
        this.state.balanceDate = res.balance.voucher_date;
        this.state.description = res.balance.memo;
        this.item.profileID = res.balance.profile_id;
        this.item.transactionID = res.balance.transaction_id;
        this.state.amount = res.balance.total_amount;
        this.state.account = res.balance.account_type;
        this.item.type = res.balance.type;
        this.item.status = res.balance.status;
      }
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.balanceService.getItems(this.itemFilter, page).then((data) => {
      this.lists = data.records;
      this.totalRecords = data.totalRecords;
      this.limit = data.limit;
      this.statement = data.statement;
    });
  }

  clearItem() {
    this.item.id = 0;
    this.item.profileID = 0;
    this.item.status = "Active";
    this.state.description = "";
    this.state.balanceDate = "";
    this.state.selectedProfile = "";
    this.item.itemList = [];
    this.state.amount = 0;
  }

  loadSearchData() {
    this.submitted = true;
    if (this.itemFilter.keyword) {
      this.goToFirstLink = 0;
      this.loadList(this.goToFirstLink);
    }
  }

  searchProfiler(event) {
    setTimeout(() => {
      this.profilerService.searchProfiler(event.query.trim()).then((data) => {
        this.profilerList = data.records;
      });
    }, 500);
  }

  saveProfile(event) {
    const profileInfo = event.value;
    this.state.selectedProfile = profileInfo.account_title;
    this.item.profileID = profileInfo.id;
  }

  fixDigits(amt) {
    return Number(amt).toFixed(2);
  }

  formatDate(date) {
    return moment(date).format("DD-MM-YYYY");
  }

  formatDateTime(date) {
    return moment(date).format("DD-MM-YYYY hh:mm A");
  }

  updateFilterStatus(obj) {
    if (obj != null && obj.loading == "Yes") {
      this.itemFilter.filterType = obj.filterName.value;
      this.itemFilter.storeID = obj.storeID.id;
      this.itemFilter.date1 = obj.date1;
      this.itemFilter.date2 = obj.date2;
      this.itemFilter.keyword = "";
      this.goToFirstLink = 0;
      this.loadList(this.goToFirstLink);
    }
    this.filterDialog = false;
  }

  openPreviewDialog(data) {
    this.previewImageDialog = true;
    this.dialogTitle = "Preview User Balance";
    this.previewHeading = "User Balance";
    this.receiptID = data.id;
  }

  updatePreviewStatus() {
    this.previewImageDialog = false;
  }

  setAccountingEntries() {
    if (this.state.account == "Debit") {
      this.item.itemList.push({
        accountID: 4,
        accountHead: "Accounts receivable",
        debitAmount: Number(this.state.amount),
        creditAmount: 0,
      });

      this.item.itemList.push({
        accountID: 4,
        accountHead: "Accounts receivable",
        debitAmount: 0,
        creditAmount: Number(this.state.amount),
      });
    } else {
      this.item.itemList.push({
        accountID: 5,
        accountHead: "Accounts payable",
        debitAmount: Number(this.state.amount),
        creditAmount: 0,
      });

      this.item.itemList.push({
        accountID: 5,
        accountHead: "Accounts payable",
        debitAmount: 0,
        creditAmount: Number(this.state.amount),
      });
    }
  }

  get currency() {
    return this.store.getters.getCurrency;
  }
}
