
import { Options, Vue } from "vue-class-component";
import SmsService from "../../service/SmsService.js";
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required} from "@vuelidate/validators";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class SmsSettings extends Vue {
  private lists = [];
  private dialogTitle;
  private keyword = '';
  private toast;
  private goToFirstLink = 0;
  private currentStoreID = 0;
  private smsService;
  private productDialog = false;
  private submitted = false;
  private statusDialog = false;
  private checkPagination = true;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private storeList = [];
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Sms Settings" },
  ];

  private item = {
    selectedStore: 0,
    id: 0,
    status: "Active",
  };
  

  private state = reactive({
    enableNotification: 'Enable',
    domainName: "",
    accountEmailAddress: "",
    tokenKey: "",
    testMobileNo: ""
  });

  private validationRules = {
    enableNotification: {
      required,
    },
    domainName: {
      required,
    },
    accountEmailAddress: {
      required,
    },
    tokenKey: {
      required,
    },
    testMobileNo: {
      required,
    }
  };

  private notificationList = [
    {
      name: 'Enable',
    },
    {
      name: 'Disable',
    }
  ];
  

  private v$ = useVuelidate(this.validationRules, this.state);

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  created() {
    this.smsService = new SmsService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.clearItem();
    this.submitted = false;
    this.dialogTitle = "Add Settings";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(isFormValid) {
    this.submitted = true;
    if (isFormValid) {
      if (this.item.id != 0) {
        this.smsService
          .updateItem(
            this.item,
            this.state
          )
          .then((res) => {
            this.loadList(this.goToFirstLink);
            //SHOW NOTIFICATION
            this.toast.handleResponse(res);
          });
      } else {
        this.smsService
          .saveItem(
            this.item,
            this.state
          )
          .then((res) => {
            this.goToFirstLink = 0;
            this.loadList(this.goToFirstLink);
            //SHOW NOTIFICATION
            this.toast.handleResponse(res);
          });
      }

      this.productDialog = false;
      this.clearItem();
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Settings";
    this.productDialog = true;

    this.smsService.getItem(data).then((res) => {
      if (res.length > 0) {
        this.state.enableNotification       = res[0].enable_notification;
        this.state.domainName               = res[0].domain_name;
        this.state.accountEmailAddress      = res[0].account_email;
        this.state.tokenKey                 = res[0].token_key;
        this.state.testMobileNo             = res[0].test_no;
        this.item.status                    = res[0].status;
        this.item.selectedStore             = res[0].branch_id;
        this.item.id                        = res[0].id;
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data) {
    this.item.id = data.id;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.item.status = "Delete";
    this.smsService.changeStatus(this.item).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.smsService.getItems(this.item.selectedStore,page).then((data) => {
      this.lists              = data.records;
      this.totalRecords       = data.totalRecords;
      this.storeList          = data.stores;
      this.limit              = data.limit;
      this.currentStoreID     = data.currentStoreID;
    });
  }

  clearItem() {
    
    this.state = {
      enableNotification: '',
      domainName: "",
      accountEmailAddress: "",
      tokenKey: "",
      testMobileNo: ""
    }; 
    
    this.item = {
      id: 0,
      selectedStore: 0,
      status: "Active",
    };
  }
}
