
import { Options, mixins   } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import ReceiptService from "../service/ReceiptService.js";
import UtilityOptions from "../mixins/UtilityOptions";
import PaymentService from "../service/PaymentService";

interface IPaymentMethod {
  bankId: number;
  branchId: number;
  cardCharges: number;
  amount: number;
  cardName: string;
  chargeCustomer: string;
  id: string;
}

interface IReceiptBalance {
  transTotalAmount: number;
}


@Options({
  props: {
    ProfilePaymentReceipt: Object,
  },
  watch: {
    ProfilePaymentReceipt(obj) {
      this.openDialog();
      this.dialogTitle = obj.dialogTitle;
      this.productDialog = obj.status;
      this.previewHeading = obj.previewHeading;

      if (obj.receiptID != 0) {
        this.loadReceipt(obj.receiptID);
      }
    },
  },
  emits: ["updatePaymentStatus"],
})
export default class ProfilePaymentReceipt extends mixins(UtilityOptions) {
  private toast;
  private productDialog = false;
  private methodList: IPaymentMethod [] = [];
  private receiptBalance: IReceiptBalance [] = [];
  private dialogTitle = "";
  private receiptService;
  private paymentService;
  private totalBill = 0;
  private previewHeading = "";
  private item = {
    id: "",
    receiptNO: "",
    type: "",
    profileId: "",
    receiptDate: "",
    receiptDueDate: "",
    selectedProfile: "",
    method: {
      cardCharges:0,
      chargeCustomer:'',
      cardName:'Cash',
      bankId:0,
      branchId:0,
      amount: 0,
      id:''
    },
    totalAmount: 0,
    description: ""
  };

  private counterEntry = [
    {
      accountID: 0,
      accountHead: "",
      amount: 0,
      type: "Debit",
    },
  ];


  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.toast = new Toaster();
    this.receiptService = new ReceiptService();
    this.paymentService = new PaymentService();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.productDialog = true;
  }

  closeDialog() {
    this.$emit("updatePaymentStatus", {});
    this.productDialog = false;
  }

  loadReceipt(receiptID) {
    this.receiptService.getReceiptData(receiptID).then((res) => {
      if (res != null) {
        
        this.item.profileId     = res.receipt.profile_id;
        this.item.receiptDate     = res.receipt.receipt_date;
        this.item.receiptDueDate  = res.receipt.receipt_due_date;
        this.item.type            = res.receipt.type;
        this.item.id              = res.receipt.id;
        this.item.receiptNO       = res.receipt.receipt_no;
        this.item.selectedProfile = res.receipt.profile_name.profileName;
        this.totalBill            = Number(res.receipt.total_bill);
        this.receiptBalance       = this.camelizeKeys(res.receipt.receipt_balance);
      }
    });

    this.paymentService.paymentMethods().then((res) => {
      this.methodList = this.camelizeKeys(res.option);

      this.methodList.push({
        cardCharges:0,
        chargeCustomer:'',
        cardName:'Cash',
        bankId:0,
        branchId:0,
        amount: 0,
        id:''
      });
    });
  }

 
  get calculateBalance() {
    let totalAmount = 0;

    this.receiptBalance.forEach((e) => {
      totalAmount = totalAmount + Number(e.transTotalAmount);
    });

    return Number((this.totalBill - totalAmount).toFixed(2));
  }

  saveReceiptPayment()
  {
    this.setAccountingEntries();
    this.receiptService.saveReceiptPayment(this.item,this.counterEntry).then((res) => {
      this.toast.handleResponse(res);
      this.closeDialog();
    });
  }

  setAccountingEntries() {
    
    this.counterEntry = [];

    let cashOrBankHeadID = 0;
    let cashOrBankHeadName = "";

    if (this.item.method.cardName == "Cash") {
      cashOrBankHeadID = 2;
      cashOrBankHeadName = "Cash in hand";
    } else {
      cashOrBankHeadID = 8;
      cashOrBankHeadName = "Cash at bank";
    }

    if (this.item.type == "SLS" || this.item.type == "INV") {
      this.counterEntry.push({
        accountID: cashOrBankHeadID,
        accountHead: cashOrBankHeadName,
        amount: this.item.totalAmount,
        type: "Debit",
      });

      this.counterEntry.push({
        accountID: 4,
        accountHead: 'Accounts receivable',
         amount: this.item.totalAmount,
        type: "Credit",
      });
    } 
    else if(this.item.type == "RFR") {
       this.counterEntry.push({
        accountID: 3,
        accountHead: 'Accounts payable',
        amount: this.item.totalAmount,
        type: "Debit",
      });

      this.counterEntry.push({
        accountID: cashOrBankHeadID,
        accountHead: cashOrBankHeadName,
         amount: this.item.totalAmount,
        type: "Credit",
      });
    }
    else {
        //
    }
  }
}
