
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import ProfilerService from "../../service/ProfilerService.js";
import UtilityOptions from "../../mixins/UtilityOptions";
import AutoComplete from "primevue/autocomplete";


interface ITransaction {
  date: string;
  receiptNo: string;
  description: string;
  type: string;
  amount: number;
  payment: number;
  balance: number;
}


@Options({
  title: 'Account Statement',
  components: {AutoComplete},
})

export default class AccountStatement extends mixins(UtilityOptions) {
  private lists : ITransaction [] = [];
  private profilerList = [];
  private profilerService;
  private reportService;
  private resultTitle = "";
  private beforeBalance = 0;
  private accountHolderPhone = "";
  private accountHolder = "";
  private storeName = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Reports", to: "reports" },
    { label: "Account Statement" , to: "account-statement" },
  ];

  private totalBalance = 0;

  private searchFilters = {
    id: "",
    date1: "",
    date2: "",
    filterType: "None",
    customerName: "",
    storeID: 0,
    profileID: 0,
  };

  private reportTypes = [
    {
      name: 'Assets',
    },
    {
      name: 'Liability',
    },
    {
      name: 'Equity',
    },
    {
      name: 'Revenue',
    },
    {
      name: 'Expense',
    },
  ];

  private filterDates = [];
  private dialogTitle;
  private submitted = false;
  private filterBranch = [];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
    this.profilerService = new ProfilerService();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.submitted = false;
    this.dialogTitle = "Filter Report";
    this.productDialog = true;
  }

  storeList()
  {
    this.reportService.getFilterList().then((res) => {
      this.filterBranch  = res.stores;
      this.filterDates   = res.datesList;
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading = true;

    this.reportService.accountStatement(this.searchFilters).then((res) => {
        const data = this.camelizeKeys(res);

        this.resultTitle = data.resultTitle;
        this.storeName = data.storeName;
        this.lists = data.record;
        this.beforeBalance = data.beforeBalance;
        this.accountHolder = data.accountHolder;
        this.accountHolderPhone = data.accountHolderPhone;
        this.loading = false;

      });
    this.productDialog = false;
  }

  checkForCredit(a)
  {
    let b = '';

    if(a < 0)
    {
      b = '('+this.formatAmount(Math.abs(a))+')';
    }
    else
    {
      b = this.formatAmount(a);
    }

    return b;
  }


  searchProfiler(event) {
    setTimeout(() => {
      this.profilerService.searchProfiler(event.query.trim()).then((data) => {
        this.profilerList = data.records;
      });
    }, 200);
  }

  saveProfile(event) {
    const profileInfo = event.value;
    this.searchFilters.customerName = profileInfo.account_title;
    this.searchFilters.profileID = profileInfo.id;
  }

  clearForFilters() {
    this.searchFilters.date1 = "";
  }

  clearSearch() {
    this.searchFilters.filterType = "None";
  }

  get currency() {
    return this.store.getters.getCurrency;
  }
}
